import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "dynamic-react-navbar";
import "dynamic-react-navbar/dist/index.css";
import { connect } from "react-redux";

class MyNavbar extends Component {
  render() {
    const { isAuthenticated, isAdmin, isSuper } = this.props.auth;
    if (isAuthenticated) {
      const links = [
        //  { caption: 'Home', path: '/', key: '1' },
        { caption: "User", path: "/user", key: "2" },
        { caption: "Brand", path: "/brand", key: "3", active: "true" },
        { caption: "Source", path: "/source", key: "4", active: "true" },
        { caption: "Requests", path: "/requests", key: "5", active: "true" },
        { caption: "Sentiment", path: "/sentiment", key: "7", active: "true" },
        { caption: "Logout", path: "/", key: "6", active: "true" },
      ];
      // if (isAdmin) {
      //   links.push({ caption: "Users Report", path: "/stats", key: "4" });
      //   links.push({
      //     caption: "Reviewers Report",
      //     path: "/review-stats",
      //     key: "5",
      //   });
      //   links.push({ caption: "Voice Queue", path: "/monitor", key: "6" });
      //   links.push({ caption: "Review", path: "/admin", key: "7" });
      // }
      // if (isSuper && isAdmin) {
      //   links.push({ caption: "Settings", path: "/settings", key: "8" });
      // }
      return <Navbar links={links} />;
    }
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(MyNavbar);
