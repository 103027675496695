import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
} from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  console.log(userData);
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      console.log(res);
      history.push("/login");
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      setCurrentUser(decoded);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const refreshToken = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      setCurrentUser(decoded);
    })
    .catch((err) => dispatch(setCurrentUser({})));
};

// export const listUsers = (userData) => (dispatch) => {
//   axios
//     .post("/api/users/list", userData)
//     .then((res) => {
//       dispatch({
//         type: ALL_RECORDS,
//         payload: res.data,
//       });
//     })
//     .catch((err) =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err,
//       })
//     );
// };

// export const editUser = (userData) => (dispatch) => {
//   axios
//     .post("/api/users/edit", userData)
//     .then((res) => {
//       dispatch({
//         type: USER_LOADING,
//         payload: res.data,
//       });
//     })
//     .catch((err) =>
//       dispatch({
//         type: GET_ERRORS,
//         payload: err,
//       })
//     );
// };

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
