import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from '../../actions/authActions';
import { connect } from 'react-redux';

class Landing extends Component {


  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to user
    if (this.props.auth.isAuthenticated) {
      this.props.logoutUser();
    }
  }

  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h4>
              <b>Welcome</b> to Insights Manager
            </h4>
            <p className="flow-text grey-text text-darken-1">
              start managing your sources
            </p>
            <br />
            <p>
              <b>Disclaimer</b> this is for admins only
            </p>
            <br />
            <div className="col s6">
              <Link
                to="/login"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Log In
              </Link>
            </div>
            <div className="col s6">
              <Link
                to="/register"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
})(Landing);
