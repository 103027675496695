import {
  SET_CURRENT_USER,
  USER_LOADING,
  LIST_USERS,
  USER_DETAILS,
  LIST_BRANDS,
  BRAND_DETAILS,
  LIST_SOURCES,
  SOURCE_DETAILS,
  LIST_DASHBOARDS,
  LIST_LANGUAGES,
  CREATE_USER,
  EDIT_USER,
  RESET_PASSWORD,
  CREATE_BRAND,
  CREATE_BRAND_ERROR,
  SENTIMENT_DETAILS,
  EDIT_SENTIMENT,
  SEND_SLACK_MESSAGE,
  SLACK_MESSAGE_ERROR,
  EDIT_SENTIMENT_ERROR
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  isSuper: false,
  canEditSentiment: false,
  email: "",
  user: {},
  loading: false,
  dashboards: [],
  languages: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isAdmin: !isEmpty(action.payload) ? action.payload.admin : false,
        isSuper: !isEmpty(action.payload) ? action.payload.super : false,
        canEditSentiment: !isEmpty(action.payload)
          ? action.payload.canEditSentiment
          : false,
        email: !isEmpty(action.payload) ? action.payload.email : "",
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LIST_USERS:
      return {
        ...state,
        users: action.payload.users,
      };
    case USER_DETAILS:
      return {
        ...state,
        users_details: action.payload.users,
      };
    case LIST_BRANDS:
      return {
        ...state,
        brands: action.payload.brands,
      };
    case BRAND_DETAILS:
      return {
        ...state,
        brands_details: action.payload.brands,
      };
    case LIST_SOURCES:
      return {
        ...state,
        sources: action.payload.sources,
      };
    case SOURCE_DETAILS:
      return {
        ...state,
        sources_details: action.payload.sources,
      };
    case SOURCE_DETAILS:
      return {
        ...state,
        sources_details: action.payload.sources,
      };
    case LIST_DASHBOARDS:
      return {
        ...state,
        dashboards: action.payload.dashboards,
      };
    case LIST_LANGUAGES:
      return {
        ...state,
        languages: action.payload.languages,
      };
    case CREATE_USER:
      return state;
    case EDIT_USER:
      return state;
    case RESET_PASSWORD:
      return state;
    case CREATE_BRAND:
      return {
        ...state,
        brandCreated: action.payload,
      };
    case CREATE_BRAND_ERROR:
      return {
        ...state,
        brandError: action.payload,
      };
    case SENTIMENT_DETAILS:
      return {
        ...state,
        comments: action.payload.comments,
      };
    case EDIT_SENTIMENT:
      return {
        ...state,
        newComment: action.payload.newComment,
        editSentimentSuccess: true
      };
    case EDIT_SENTIMENT_ERROR:
      return {
        ...state,
        editSentimentSError: true
      };
    case SEND_SLACK_MESSAGE:
      return {
        ...state,
        slackMessageSent: action.payload,
      };
    case SLACK_MESSAGE_ERROR:
      return {
        ...state,
        slackMessageError: action.payload,
      };
    default:
      return state;
  }
}
