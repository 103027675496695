import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { logoutUser } from '../../actions/authActions';
import { handleRequest } from '../../actions/requestActions';

class RequestsTab extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {};
    this.init = this.init.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { requestId } = this.props.match.params;
    console.log('requestId', requestId);

    this.props.handleRequest(requestId, this.props.auth);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      this.setState({
        sources: nextProps.auth.sources,
        sources_details: nextProps.auth.sources_details
          ? nextProps.auth.sources_details
          : [],
      });
    }
  }

  init() {
    // init
  }

  onSourceChange(e, value) {
    this.setState({
      selectedSources: value,
    });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        <div className="row">
          {this.props.requests.requestHandlingInProgress && (
            <div className="landing-copy col s12 center-align">
              <h5>Please wait. Processing your request..</h5>
              <CircularProgress />
            </div>
          )}

          {!this.props.requests.requestHandlingInProgress &&
            !this.props.requests.requestHandled && (
              <div className="landing-copy col s12 center-align">
                <h5>Request Failed.</h5>
                <p>{this.props.requests.message}</p>
                <Button variant="text" onClick={() => { }} href={'/requests'}>
                  GO BACK
                </Button>
              </div>
            )}

          {!this.props.requests.requestHandlingInProgress &&
            this.props.requests.requestHandled && (
              <div className="landing-copy col s12 center-align">
                <h5>Request successfully handled.</h5>
                <Button variant="text" onClick={() => { }} href={'/requests'}>
                  GO TO REQUESTS
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}

RequestsTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, {
  logoutUser,
  handleRequest,
})(RequestsTab);
