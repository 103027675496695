import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { logoutUser } from "../../actions/authActions";
import { listRequests } from "../../actions/requestActions";

import RequestsTable from "./RequestsTable";

class RequestsTab extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      sources: [],
      selectedSources: [],
      sources_details: [],
      requests: [],
    };
    this.init = this.init.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.auth) {
      this.setState({
        sources: nextProps.auth.sources,
        sources_details: nextProps.auth.sources_details
          ? nextProps.auth.sources_details
          : [],
      });
    }
  }

  init() {
    console.log(this.props.requests.requestsList);
    this.props.listRequests(this.props.auth);
  }

  onSourceChange(e, value) {
    this.setState({
      selectedSources: value,
    });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { user } = this.props.auth;
    const { requests } = this.props;

    console.log(requests.requestsList);

    return (
      <div>
        <div className="row">
          <div className="landing-copy col s12 center-align">
            <h4>
              Manage <b>Requests</b>
            </h4>

            <div className="row">
              <RequestsTable requests={requests.requestsList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RequestsTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  listRequests: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, {
  logoutUser,
  listRequests,
})(RequestsTab);
