import axios from "axios";

import {
  GET_ERRORS,
  LIST_USERS,
  USER_DETAILS,
  LIST_BRANDS,
  BRAND_DETAILS,
  LIST_SOURCES,
  SOURCE_DETAILS,
  LIST_DASHBOARDS,
  LIST_LANGUAGES,
  CREATE_USER,
  EDIT_USER,
  RESET_PASSWORD,
  CREATE_BRAND,
  CREATE_BRAND_ERROR,
  SENTIMENT_DETAILS,
  EDIT_SENTIMENT,
  EDIT_SENTIMENT_ERROR,
  SEND_SLACK_MESSAGE,
  SLACK_MESSAGE_ERROR
} from "./types";

export const listUsers = () => (dispatch) => {
  axios
    .get("/api/user-tab/list")
    .then((res) => {
      dispatch({
        type: LIST_USERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const getUserDetails = (users) => (dispatch) => {
  axios
    .post("/api/user-tab/user-details", users)
    .then((res) => {
      dispatch({
        type: USER_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const listBrands = () => (dispatch) => {
  axios
    .get("/api/brand-tab/list")
    .then((res) => {
      dispatch({
        type: LIST_BRANDS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const getBrandDetails = (brands) => (dispatch) => {
  axios
    .post("/api/brand-tab/brand-details", brands)
    .then((res) => {
      dispatch({
        type: BRAND_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const createBrand = (info) => (dispatch) => {
  axios
    .post("/api/brand-tab/addbrand", info)
    .then((response) => {
      console.log("response", response);
      return axios.get("/api/brand-tab/list");
    })
    .then((res) => {
      dispatch({
        type: LIST_BRANDS,
        payload: res.data,
      });
      dispatch({
        type: CREATE_BRAND,
        payload: true,
      });
    })
    .catch((err) => {
      console.log("error", err);
      dispatch({
        type: CREATE_BRAND_ERROR,
        payload: true,
      });
    });
};
export const UploadEntityImage = (info) => (dispatch) => {
  axios
    .post("/api/brand-tab/UploadEntityImage", info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response.data, "response");
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export const listSources = (name) => (dispatch) => {
  let url = "/api/source-tab/list";

  if (name) {
    url += `?name=${name}`;
  }

  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: LIST_SOURCES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const getSourceDetails = (sources) => (dispatch) => {
  axios
    .post("/api/source-tab/source-details", sources)
    .then((res) => {
      dispatch({
        type: SOURCE_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const listDashboards = () => (dispatch) => {
  axios
    .get("/api/user-tab/list-dashboards")
    .then((res) => {
      dispatch({
        type: LIST_DASHBOARDS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const listLanguages = () => (dispatch) => {
  axios
    .get("/api/user-tab/list-languages")
    .then((res) => {
      dispatch({
        type: LIST_LANGUAGES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const createUser = (user) => (dispatch) => {
  axios
    .post("/api/user-tab/addUser", { user })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: CREATE_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log("errrorrr");
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const editUser = (user) => (dispatch) => {
  console.log("userrrrrrr", user);
  axios
    .patch(`/api/user-tab/editUser/${user.user.username}`, { ...user })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: EDIT_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const listEntities = () => (dispatch) => {
  axios
    .get("/api/user-tab/list-entities")
    .then((res) => {
      dispatch({
        type: LIST_BRANDS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const resetPassword = (user) => (dispatch) => {
  axios
    .post("/api/user-tab/resetPassword", user)
    .then((res) => {
      dispatch({
        type: RESET_PASSWORD,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const listSentimentDetails = (user) => (dispatch) => {
  axios
    .post("/api/sentiment-tab/sentiment", user)
    .then((res) => {
      dispatch({
        type: SENTIMENT_DETAILS,
        payload: res.data,
      });
     })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};
export const sendSentimentNotification = (notification) => (dispatch) => {
  axios
    .post("/api/sentiment-tab/send-slack-message", notification)
    .then((res) => {
      dispatch({
        type: SEND_SLACK_MESSAGE,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: SLACK_MESSAGE_ERROR,
        payload: true,
      })
    } );
};

export const editSentiment = (user) => (dispatch) => {
  axios
    .put("/api/sentiment-tab/sentiment", user)
    .then((res) => {
      dispatch({
        type: EDIT_SENTIMENT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: EDIT_SENTIMENT_ERROR,
        payload: err,
      })
    );
};
