import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SvgIcon from "@material-ui/core/SvgIcon";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import jwt from "jsonwebtoken";

import { logoutUser } from "../../actions/authActions";
import {
  listUsers,
  getUserDetails,
  listEntities,
  listDashboards,
  listLanguages,
  createUser,
  editUser,
  resetPassword,
} from "../../actions/sourceActions";
import { createRequest } from "../../actions/requestActions";

class UserTab extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      users: [],
      brands: [],
      dashboards: [],
      supported_languages: [],
      selectedUsers: [],
      users_details: [],
      pullDataModalOpen: false,
      selectedSource: null,
      startDate: null,
      endDate: null,
      activateSourceModalOpen: false,
      editSourceModalOpen: false,
      selectedInterval: null,
      activateUserModalOpen: false,
      selectedUser: null,
      selectedFlag: null,
      // editFlagsModalOpen: false,

      // originalFlags: {},
      // flagsObject: {},
      editChanges: {},

      createUserModel: false,
      editUserModal: false,
      selectedBrands: [],
      selectedMainBrand: false,
      selectedDashboards: [],
      selectedLanguages: [],
      newUser: null,
      newUsername: null,
      newEmail: null,
      newPhone: null,
      hasSubDrivers: false,
      hasAIDrivers: false,
      canEditSentiment: false,
      canExcludePagePosts: false,
      exportRawData: false,
      canExportVisToExcel: false,
      hasUncategorizedDrivers: false,
      demoAccount: false,

      user_defined_queries: false,
      raw_data_discover: false,

      validityPeriod: null,

      isAdmin: false,
      accessUsers: [],
      accessDashboards: [],
      actions: [],

      password: "",
      confirmPassword: "",
      showPassword: false,
      passwordMismatch: false,
      error: null,
      info: null,

      extraDashboards: [
        "Queries Dashboard",
        "Self Summary Dashboard",
        "Competitive Summary Dashboard",
        "Text Summary Dashboard",
        "Universe Summary Dashboard",
        "Brand Conversations",
      ],

      resetPasswordModal: false,
    };
    this.init = this.init.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onDisplayClick = this.onDisplayClick.bind(this);
    this.validatePasswordStrength = this.validatePasswordStrength.bind(this);
    this.handleSubmitCreateUser = this.handleSubmitCreateUser.bind(this);
    this.handleSubmitEditUser = this.handleSubmitEditUser.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      let newDashes = nextProps.auth.dashboards.map((e) => {
        return e.title;
      });
      newDashes.push(...this.state.extraDashboards);
      this.setState({
        users: nextProps.auth.users,
        brands: nextProps.auth.brands,
        dashboards: nextProps.auth.dashboards,
        allDashboards: newDashes,
        supported_languages: nextProps.auth.languages,
        users_details: nextProps.auth.users_details
          ? nextProps.auth.users_details
          : [],
      });
    }
  }

  init() {
    this.props.listUsers();
  }

  onUserChange(e, value) {
    this.setState({
      selectedUsers: value,
    });
  }

  onDisplayClick(e) {
    e.preventDefault();
    this.props.getUserDetails({ users: this.state.selectedUsers });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  validatePasswordStrength(password) {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  handleSubmitCreateUser = (e) => {
    e.preventDefault();

    if (!this.state.error && !this.state.passwordMismatch) {
      console.log("Form Submitted Correctly");

      let entities = [];
      let mainBrand = null;
      this.state.selectedBrands.map((item) => {
        const entity = this.state.brands.filter((obj) => obj.name == item);
        entities.push(entity[0].id);
        if (this.state.selectedMainBrand === item) {
          mainBrand = entity[0].id;
        }
      });

      let dashboards = [];
      let userDefinedQueries = false;
      let rawDataDiscover = false;
      let hasSelfSummary = false;
      let hasCompetitiveSummary = false;
      let hasTextSummary = false;
      let hasUniverseSummary = false;
      let customDashboard = false;

      this.state.selectedDashboards.map((item) => {
        switch (item) {
          case "Queries Dashboard":
            this.setState({ user_defined_queries: true });
            userDefinedQueries = true;
            break;
          case "Universe Summary Dashboard":
            hasUniverseSummary = true;
            break;
          case "Self Summary Dashboard":
            hasSelfSummary = true;
            break;
          case "Competitive Summary Dashboard":
            hasCompetitiveSummary = true;
            break;
          case "Text Summary Dashboard":
            hasTextSummary = true;
            break;
          case "Universe Summary Dashboard":
            hasUniverseSummary = true;
            break;
          case "Brand Conversations":
            rawDataDiscover = true;
            break;
          case "Custom Dashboard":
            customDashboard = true;
            break;
          default:
            const dash = this.state.dashboards.filter(
              (obj) => obj.title == item
            );
            dashboards.push(dash[0].unique_id);
            break;
        }
      });

      let access_dashboards = this.state.accessDashboards
        ? this.state.accessDashboards
            .map((item) => {
              const dash = this.state.dashboards.filter(
                (obj) => obj.title == item
              );
              return dash[0] ? dash[0].unique_id : undefined;
            })
            .filter((elem) => elem !== undefined)
        : [];

      const accountExpiry = new Date(this.state.validityPeriod);

      accountExpiry.setUTCHours(0, 0, 0, 0);

      this.props.createUser({
        user: {
          username: `${this.state.newUsername}`,
          email: `${this.state.newEmail}`,
          password: `${this.state.password}`,
          phoneNumber: `+02${this.state.newPhone}`,
          validity: `${this.state.validityPeriod}`,
        },
        role: {
          // email: `${this.state.newEmail}`,
          // phoneNumber: `+02${this.state.newPhone}`,
          dashboards: dashboards,
          main_languages: this.state.selectedLanguages,

          entities: entities,
          main_entity: `${mainBrand}`,

          demoAccount: `${this.state.demoAccount}`,
          listening_queries: `${userDefinedQueries}`,
          // raw_data_discover: `${rawDataDiscover}`,
          accountExpiry,

          // hasSelfSummary,
          // hasCompetitiveSummary,
          // hasTextSummary,
          // hasUniverseSummary,
          customDashboard,

          authorization: {
            level: this.state.isAdmin ? "ADMIN" : "USER",
            access: {
              actions: this.state.actions,
              dashboards: access_dashboards,
              entities: entities,
              users: this.state.accessUsers,
            },
          },
        },
        createdBy: this.props.auth.user.email,
      });
      this.setState({
        createUserModel: false,
        selectedBrands: [],
        selectedMainBrand: false,
        selectedDashboards: [],
        selectedLanguages: [],
        newUser: null,
        newUsername: null,
        newEmail: null,
        newPhone: null,
        hasSubDrivers: false,
        hasAIDrivers: false,
        canEditSentiment: false,
        canExcludePagePosts: false,
        exportRawData: false,
        canExportVisToExcel: false,
        hasUncategorizedDrivers: false,
        demoAccount: false,

        user_defined_queries: false,
        raw_data_discover: false,

        validityPeriod: null,

        isAdmin: false,
        accessUsers: [],
        accessDashboards: [],
        actions: [],

        password: "",
        confirmPassword: "",
        showPassword: false,
        error: null,
        info: null,
      });
    }
  };

  handleSubmitEditUser = (e) => {
    e.preventDefault();

    let entities = [];
    let mainEntity = null;

    this.state.selectedBrands.map((item) => {
      const entity = this.state.brands.filter((obj) => obj.name == item);
      entities.push(entity[0].id);
      if (this.state.selectedMainBrand === item) {
        mainEntity = entity[0].id;
      }
    });

    let dashboards = [];
    let access_dashboards = [];
    let userDefinedQueries = false;
    let rawDataDiscover = false;
    let hasSelfSummary = false;
    let hasCompetitiveSummary = false;
    let hasTextSummary = false;
    let hasUniverseSummary = false;
    let customDashboard = false;

    this.state.selectedDashboards.map((item) => {
      switch (item) {
        case "Queries Dashboard":
          this.setState({ user_defined_queries: true });
          userDefinedQueries = true;
          break;
        case "Self Summary Dashboard":
          hasSelfSummary = true;
          break;
        case "Competitive Summary Dashboard":
          hasCompetitiveSummary = true;
          break;
        case "Text Summary Dashboard":
          hasTextSummary = true;
          break;
        case "Brand Conversations":
          this.setState({ raw_data_discover: true });
          rawDataDiscover = true;
          break;
        case "Universe Summary Dashboard":
          hasUniverseSummary = true;
          break;
        case "Custom Dashboard":
          customDashboard = true;
          break;
        default:
          const dash = this.state.dashboards.filter((obj) => obj.title == item);
          if (dash[0]) dashboards.push(dash[0].unique_id);
          break;
      }
    });

    this.state.accessDashboards.map((item) => {
      const dash = this.state.dashboards.filter((obj) => obj.title == item);
      if (dash[0]) access_dashboards.push(dash[0].unique_id);
    });

    let authorization = {};

    if (!this.state.isAdmin) {
      let access = {};
      access.actions = [];
      access.dashboards = [];
      access.entities = [];
      access.users = [];
      authorization.access = access;
      authorization.level = "USER";
    } else {
      let access = {};
      access.actions = this.state.actions;
      access.dashboards = access_dashboards;
      access.entities = entities;
      access.users = this.state.accessUsers;
      authorization.access = access;
      authorization.level = "ADMIN";
    }

    const accountExpiry = new Date(this.state.validityPeriod);

    accountExpiry.setUTCHours(0, 0, 0, 0);
    this.props.createRequest({
      action: "EDIT_USER",
      name: `${this.state.newUsername}`,
      email: `${this.props.auth.user.email}`,
      details: {
        user: {
          username: `${this.state.newUsername}`,
          email: `${this.state.newEmail}`,
          password: `${this.state.password}`,
          phoneNumber: `+02${this.state.newPhone}`,
          validity: `${this.state.validityPeriod}`,
        },
        role: {
          dashboards: dashboards,
          main_languages: this.state.selectedLanguages,
          // email: `${this.state.newEmail}`,
          // phoneNumber: `+02${this.state.newPhone}`,

          entities: entities,
          main_entity: mainEntity,

          metrics_flag: this.state.hasSubDrivers,
          hasAIDrivers: this.state.hasAIDrivers,
          canEditSentiment: this.state.canEditSentiment,
          canExcludePagePosts: this.state.canExcludePagePosts,
          exportRawData: this.state.exportRawData,
          canExportVisToExcel: this.state.canExportVisToExcel,
          hasUncategorizedDrivers: this.state.hasUncategorizedDrivers,
          demoAccount: this.state.demoAccount,
          listening_queries: userDefinedQueries,
          raw_data_discover: rawDataDiscover,
          accountExpiry,
          customDashboard,
          // hasSelfSummary,
          // hasCompetitiveSummary,
          // hasTextSummary,
          // hasUniverseSummary,
          authorization,
        },
        editChanges: this.state.editChanges,
      },
    });
    this.setState({
      editUserModal: false,
      selectedBrands: [],
      selectedMainBrand: false,
      selectedDashboards: [],
      selectedLanguages: [],
      newUser: null,
      newUsername: null,
      newEmail: null,
      newPhone: null,
      hasSubDrivers: false,
      hasAIDrivers: false,
      canEditSentiment: false,
      canExcludePagePosts: false,
      exportRawData: false,
      canExportVisToExcel: false,
      hasUncategorizedDrivers: false,
      demoAccount: false,

      validityPeriod: null,

      isAdmin: false,
      accessUsers: [],
      accessDashboards: [],
      actions: [],

      password: "",
      confirmPassword: "",
      showPassword: false,
      error: null,
      info: null,

      editChanges: {},
    });
  };

  handleResetPassword = (e) => {
    e.preventDefault();
    console.log("User confirmed Sending Autogenerated Password");

    // Logic Here ..
    this.props.resetPassword({
      user: this.state.selectedUser,
      email: this.state.newEmail,
    });

    this.setState({
      resetPasswordModal: false,
      selectedUser: null,
      newEmail: null,
    });
  };

  render() {
    const { user } = this.props.auth;

    const getChildren = (obj, options = {}) =>
      Object.keys(obj).map((k) => {
        try {
          const hasChildren = typeof obj[k] === "object";
          const sources =
            Array.isArray(obj[k]) && k === "sources" ? obj[k] : null;

          return {
            id: Math.random() * 10000,
            name: `${obj[k].name || k}${hasChildren ? "" : `: ${obj[k]}`}`,
            children: hasChildren ? getChildren(obj[k], { sources }) : [],
            sources: options.sources,
          };
        } catch (err) {
          console.log(k);
        }
      });

    const getData = (objs) => {
      return objs.map((obj) => ({
        id: Math.random() * 10000,
        name: obj.user,
        children: getChildren(obj),
        user: obj,
      }));
    };

    const renderTree = (nodes, index) => {
      return (
        <TreeItem key={nodes.id} nodeId={nodes.id + ""} label={nodes.name}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node, i) => renderTree(node, i))
            : null}
          {nodes.user && nodes.user.demoAccount && (
            <Button
              variant="text"
              onClick={() => {
                this.setState({
                  activateUserModalOpen: true,
                  selectedUser: nodes.user,
                  selectedInterval: "",
                });
              }}
            >
              Activate User
            </Button>
          )}
          {nodes.user && nodes.user.features && (
            <>
              <Button
                onClick={() => {
                  this.props.listEntities();
                  this.props.listDashboards();
                  this.props.listLanguages();

                  let selectedUserBrands = nodes.user.brands.map((brand) => {
                    return brand.name;
                  });
                  this.setState({
                    selectedUser: nodes.user,
                    newEmail: nodes.user.email,
                    actions: nodes.user.actions,
                    selectedDashboards: nodes.user.dashboards,
                    selectedBrands: selectedUserBrands,
                    selectedMainBrand: nodes.user.main_brand,
                    accessUsers: nodes.user.associated_users,
                    selectedLanguages: nodes.user.main_languages,
                    newUsername: nodes.user.user,
                    accessDashboards: nodes.user.accessDashboards,
                    metrics_flag: nodes.user.features.metrics_flag,
                    hasSubDrivers: nodes.user.features.metrics_flag,
                    hasAIDrivers: nodes.user.features.hasAIDrivers,
                    canEditSentiment: nodes.user.features.canEditSentiment,
                    canExcludePagePosts:
                      nodes.user.features.canExcludePagePosts,
                    exportRawData: nodes.user.features.exportRawData,
                    canExportVisToExcel:
                      nodes.user.features.canExportVisToExcel,
                    hasUncategorizedDrivers:
                      nodes.user.features.hasUncategorizedDrivers,
                    demoAccount: nodes.user.demoAccount,
                    validityPeriod: nodes.user.accountExpiry,
                    isAdmin: nodes.user.role === "ADMIN" ? true : false,
                    editUserModal: true,
                  });
                }}
              >
                Edit User
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button
                onClick={() => {
                  const token = jwt.sign(
                    {
                      username: nodes.user.user,
                      adminUsername: this.props.auth.user.name,
                    },
                    process.env.REACT_APP_SECRERT,
                    {
                      expiresIn: "5m",
                    }
                  );
                  window.open(
                    `https://www.eaimtechnologies.com/impersonate?token=${token}&as=${nodes.user.user}`
                  );
                }}
              >
                Impersonate
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button
                onClick={() => {
                  this.setState({
                    selectedUser: nodes.user.user,
                    newEmail: nodes.user.email,
                    resetPasswordModal: true,
                  });
                }}
              >
                Reset User Password
              </Button>
            </>
          )}
          {nodes.sources && nodes.sources.length && (
            <>
              {/* Showing button to request data pull */}
              <Button
                variant="text"
                onClick={() => {
                  this.setState({
                    pullDataModalOpen: true,
                    selectedSource: nodes.sources[index],
                  });
                }}
              >
                Request data pull
              </Button>
              &nbsp; &nbsp; &nbsp;
              {/* Source is not active, showing button to activate */}
              {!nodes.sources[index].active && (
                <Button
                  variant="text"
                  onClick={() => {
                    this.setState({
                      activateSourceModalOpen: true,
                      selectedSource: nodes.sources[index],
                    });
                  }}
                >
                  Activate Source
                </Button>
              )}
              &nbsp; &nbsp; &nbsp;
              {/* Other edits on source */}
              <Button
                variant="text"
                onClick={() => {
                  this.setState({
                    editSourceModalOpen: true,
                    selectedSource: nodes.sources[index],
                    selectedInterval: nodes.sources[index].interval_group,
                  });
                }}
              >
                Edit Source
              </Button>
              &nbsp; &nbsp; &nbsp;
            </>
          )}
        </TreeItem>
      );
    };

    function MinusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
      );
    }

    function PlusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
      );
    }

    function CloseSquare(props) {
      return (
        <SvgIcon
          className="close"
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="landing-copy col s12 center-align">
            <h4>
              <b>Welcome</b> {user.name.split(" ")[0]}
            </h4>
            <div className="row">
              <div className="col s8">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={this.state.users}
                  filterSelectedOptions
                  onChange={this.onUserChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Selected Users"
                      placeholder="User"
                    />
                  )}
                />
              </div>
              <div className="row">
                <div className="col s2">
                  <button
                    style={{
                      width: "150px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                    }}
                    onClick={this.onDisplayClick}
                    className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                  >
                    Display
                  </button>
                </div>
                <div className="col s2">
                  <button
                    style={{
                      width: "150px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                    }}
                    onClick={() => {
                      this.props.listEntities();
                      this.props.listDashboards();
                      this.props.listLanguages();

                      this.setState({
                        createUserModel: true,
                      });
                    }}
                    className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                  >
                    Create New User
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s8">
                <TreeView
                  style={{ textAlign: "left" }}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  // defaultEndIcon={<CloseSquare />}
                  multiSelect
                >
                  {getData(this.state.users_details).map(renderTree)}
                </TreeView>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.activateUserModalOpen}
          onClose={() => {
            this.setState({
              activateUserModalOpen: false,
              selectedUser: null,
              selectedInterval: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to activate:{" "}
              {this.state.selectedUser ? this.state.selectedUser.user : ""}
            </Typography>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedInterval}
              label="Interval"
              onChange={(e) => {
                this.setState({ selectedInterval: e.target.value });
              }}
            >
              <MenuItem value="none" disabled>
                Interval Group
              </MenuItem>
              <MenuItem value={"1-min"}>1 minute</MenuItem>
              <MenuItem value={"5-min"}>5 minutes</MenuItem>
              <MenuItem value={"15-min"}>15 minutes</MenuItem>
              <MenuItem value={"1-hour"}>1 hour</MenuItem>
              <MenuItem value={"6-hour"}>6 hours</MenuItem>
              <MenuItem value={"1-day"}>1 day</MenuItem>
            </Select>

            <br />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From"
                inputFormat="MM/dd/yyyy"
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                label="To"
                inputFormat="MM/dd/yyyy"
                value={this.state.endDate}
                onChange={(value) => {
                  this.setState({ endDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <Button
              variant="outlined"
              disabled={
                this.state.startDate > this.state.endDate ? true : false
              }
              onClick={() => {
                this.props.createRequest({
                  action: "ACTIVATE_ACCOUNT",
                  email: `${user.name}`,
                  name: `${this.state.selectedUser.user}`,
                  details: {
                    username: this.state.selectedUser.user,
                    sources: this.state.selectedUser.brands
                      .map((b) => b.sources)
                      .flat(),
                    interval_group: this.state.selectedInterval,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                  },
                });
                this.setState({
                  activateUserModalOpen: false,
                  selectedUser: null,
                  selectedInterval: null,
                  startDate: null,
                  endDate: null,
                });
              }}
            >
              Request to activate
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.pullDataModalOpen}
          onClose={() => {
            this.setState({
              pullDataModalOpen: false,
              startDate: null,
              endDate: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to pull data
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From"
                inputFormat="MM/dd/yyyy"
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                label="To"
                inputFormat="MM/dd/yyyy"
                value={this.state.endDate}
                onChange={(value) => {
                  this.setState({ endDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {this.state.selectedSource &&
              this.state.selectedSource.gaps &&
              this.state.selectedSource.gaps.length && (
                <Button
                  variant="text"
                  onClick={() => {
                    const gaps = this.state.selectedSource.gaps.map((g) => {
                      const gap = new Date();
                      gap.setDate(1);
                      gap.setYear(g.split("-")[0]);
                      gap.setMonth(g.split("-")[1] - 1);
                      return gap;
                    });

                    gaps.sort((a, b) => a < b);
                    const startDate = gaps[0];
                    const endDate = gaps[gaps.length - 1];
                    endDate.setMonth(endDate.getMonth() + 1);
                    endDate.setDate(endDate.getDate() - 1);
                    this.setState({
                      startDate,
                      endDate,
                    });
                  }}
                >
                  Fill Gaps
                </Button>
              )}

            <Button
              variant="outlined"
              onClick={() => {
                const today = new Date();
                const lastTwoWeeks = new Date();
                lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 15);

                this.props.createRequest({
                  action: "PULL_DATA",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                    startDate: this.state.startDate || lastTwoWeeks,
                    endDate: this.state.endDate || today,
                  },
                });
                this.setState({
                  pullDataModalOpen: false,
                  startDate: null,
                  endDate: null,
                });
              }}
            >
              Send Request
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.activateSourceModalOpen}
          onClose={() => {
            this.setState({
              activateSourceModalOpen: false,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to activate:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                this.props.createRequest({
                  action: "ACTIVATE_SOURCE",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                  },
                });
                this.setState({
                  activateSourceModalOpen: false,
                });
              }}
            >
              Request to activate
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.editSourceModalOpen}
          onClose={() => {
            this.setState({
              editSourceModalOpen: false,
              selectedInterval: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedInterval}
              label="Interval"
              onChange={(e) => {
                this.setState({ selectedInterval: e.target.value });
              }}
            >
              <MenuItem value={"1-min"}>1 minute</MenuItem>
              <MenuItem value={"5-min"}>5 minutes</MenuItem>
              <MenuItem value={"15-min"}>15 minutes</MenuItem>
              <MenuItem value={"1-hour"}>1 hour</MenuItem>
              <MenuItem value={"6-hour"}>6 hours</MenuItem>
              <MenuItem value={"1-day"}>1 day</MenuItem>
            </Select>

            <Button
              variant="outlined"
              onClick={() => {
                if (this.state.selectedInterval) {
                  this.props.createRequest({
                    action: "EDIT_SOURCE",
                    email: `${user.name}`,
                    name: this.state.selectedSource.name,
                    details: {
                      source: this.state.selectedSource,
                      interval_group: this.state.selectedInterval,
                    },
                  });
                  this.setState({
                    editSourceModalOpen: false,
                    selectedInterval: null,
                  });
                }
              }}
            >
              Edit
            </Button>
          </Box>
        </Modal>

        <Dialog
          open={this.state.createUserModel}
          onBackdropClick="false"
          disableBackdropClick={true}
          onClose={() => {
            this.setState({
              createUserModel: false,
            });
          }}
        >
          <DialogTitle>Create New User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here, you can define user credentials and access privileges for a
              new user.
            </DialogContentText>

            <br />
            <br />

            <form id="create-user-form" onSubmit={this.handleSubmitCreateUser}>
              <TextField
                required
                id="username"
                label="Username"
                type=""
                fullWidth
                variant="standard"
                value={this.state.newUsername}
                onChange={(e) => {
                  this.setState({ newUsername: e.target.value });
                }}
              />

              <br />
              <br />

              <TextField
                required
                id="email"
                label="Email Address"
                type=""
                fullWidth
                variant="standard"
                value={this.state.newEmail}
                onChange={(e) => {
                  this.setState({ newEmail: e.target.value });
                }}
              />

              <br />
              <br />

              <TextField
                required
                id="phone"
                label="Mobile number"
                type="number"
                fullWidth
                variant="standard"
                value={this.state.newPhone}
                onChange={(e) => {
                  this.setState({ newPhone: e.target.value });
                }}
              />

              <br />
              <br />

              <FormControl
                fullwidth
                sx={{ m: 0, width: "100%" }}
                variant="standard"
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password *
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  required
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  error={this.state.error ? true : false}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  onFocus={() => {
                    this.setState({
                      info:
                        "Password must be not less than 8 characters and should contains at least one capital letter, small letter, special character and a number!",
                    });
                  }}
                  onBlur={() => {
                    this.setState({ info: null });
                    if (!this.validatePasswordStrength(this.state.password)) {
                      this.setState({
                        error:
                          "Make sure password is not less than 8 characters and contains at least one capital letter, small letter, special character and a number!",
                      });
                    } else {
                      this.setState({ error: null });
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <br />
              {this.state.info && (
                <Alert severity="info" sx={{ width: "100%" }}>
                  {this.state.info}
                </Alert>
              )}
              <br />

              {this.state.error && (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={this.state.error ? true : false}
                  autoHideDuration={1000}
                >
                  <Alert severity="error" sx={{ width: "100%" }}>
                    {this.state.error}
                  </Alert>
                </Snackbar>
              )}

              <FormControl
                fullwidth
                sx={{ m: 0, width: "100%" }}
                variant="standard"
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Confirm Password *
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  required
                  error={this.state.passwordMismatch ? true : false}
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.confirmPassword}
                  onChange={(e) => {
                    this.setState({ confirmPassword: e.target.value });
                    if (this.state.password === e.target.value) {
                      this.setState({ passwordMismatch: false });
                    } else {
                      this.setState({ passwordMismatch: true });
                    }
                  }}
                  onBlur={() => {
                    if (this.state.password === this.state.confirmPassword) {
                      this.setState({ passwordMismatch: false });
                    } else {
                      this.setState({ passwordMismatch: true });
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              {this.state.passwordMismatch && (
                <Alert severity="error" sx={{ width: "100%" }}>
                  {
                    "Password mismatch! Please make sure confirm password matches with your input passowrd."
                  }
                </Alert>
              )}
              <br />

              <Autocomplete
                multiple
                disableCloseOnSelect={true}
                id="tags-standard"
                options={
                  this.state.brands
                    ? this.state.brands.map((item, index) => {
                        return item.name;
                      })
                    : []
                }
                filterSelectedOptions
                onChange={(e, value) => {
                  this.setState({ selectedBrands: value });
                }}
                value={this.state.selectedBrands}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Brands"
                    placeholder="Brand"
                    required
                    inputProps={{
                      ...params.inputProps,
                      required: this.state.selectedBrands.length === 0,
                    }}
                  />
                )}
              />

              <br />
              <br />

              <Autocomplete
                disableCloseOnSelect={false}
                id="mainbrand"
                options={
                  this.state.selectedBrands ? this.state.selectedBrands : []
                }
                filterSelectedOptions
                onChange={(e, value) => {
                  this.setState({ selectedMainBrand: value });
                }}
                value={this.state.selectedMainBrand}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Main Brand"
                    placeholder="Main Brand"
                    required
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <br />
              <br />

              <Autocomplete
                multiple
                id="tags-standard"
                options={
                  this.state.allDashboards ? this.state.allDashboards : []
                }
                filterSelectedOptions
                disableCloseOnSelect={true}
                onChange={(e, value) => {
                  this.setState({ selectedDashboards: value });
                }}
                value={this.state.selectedDashboards}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Dashboards"
                    placeholder="Dashboard"
                    inputProps={{
                      ...params.inputProps,
                      required: this.state.selectedDashboards.length === 0,
                    }}
                  />
                )}
              />

              <br />
              <br />

              <InputLabel id="demo-simple-select-autowidth-label2">
                Supported Languages *
              </InputLabel>
              <Select
                labelId="languages"
                id="languages"
                value={this.state.selectedLanguages}
                label="Languages"
                placeholder="languages"
                fullWidth
                required
                variant="standard"
                multiple
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  this.setState({
                    selectedLanguages:
                      typeof value === "string" ? value.split(",") : value,
                  });
                }}
              >
                {this.state.supported_languages &&
                  this.state.supported_languages.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="demoAccount"
                    checked={this.state.demoAccount}
                    onChange={(e) => {
                      this.setState({
                        demoAccount: e.target.checked,
                      });
                    }}
                  />
                }
                label="demoAccount"
              />

              <br />
              <br />

              <InputLabel id="validity-title">Account Expiry *</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="To"
                  inputFormat="MM/dd/yyyy"
                  required
                  disablePast
                  value={this.state.validityPeriod}
                  onChange={(value) => {
                    this.setState({ validityPeriod: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onChange={(e) => {
                        const today = new Date(Date.now());
                        var inputDate = new Date(e.target.value);
                        if (inputDate < today) {
                          this.setState({
                            error:
                              "Please input a valid date! Which is not earlier than today.",
                          });
                        } else {
                          this.setState({ error: null });
                        }
                      }}
                      required
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="isAdmin"
                    checked={this.state.isAdmin}
                    onChange={(e) => {
                      this.setState({
                        isAdmin: e.target.checked,
                      });
                    }}
                  />
                }
                label="isAdmin"
              />

              <br />
              <br />

              {this.state.isAdmin && (
                <>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="AccessUsers"
                    options={this.state.users ? this.state.users : []}
                    filterSelectedOptions
                    onChange={(e, value) => {
                      this.setState({ accessUsers: value });
                    }}
                    value={this.state.accessUsers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Access Users"
                        placeholder="Access"
                      />
                    )}
                  />

                  <br />
                  <br />

                  <InputLabel id="AccessDashboards">
                    Access Dashboards
                  </InputLabel>
                  <Select
                    labelId="AccessDashboards"
                    id="AccessDashboards"
                    value={this.state.accessDashboards}
                    label="Access Dashboards"
                    placeholder="Access Dashboards"
                    multiple
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      this.setState({
                        accessDashboards:
                          typeof value === "string" ? value.split(",") : value,
                      });
                    }}
                  >
                    {this.state.selectedDashboards &&
                      this.state.selectedDashboards
                        .filter(
                          (el) => !this.state.extraDashboards.includes(el)
                        )
                        .map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                  </Select>

                  <br />
                  <br />

                  <InputLabel id="Actions">Actions *</InputLabel>
                  <Select
                    labelId="Actions"
                    id="Actions"
                    value={this.state.actions}
                    label="Actions"
                    placeholder="Actions"
                    multiple
                    fullWidth
                    required
                    variant="standard"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      this.setState({
                        actions:
                          typeof value === "string" ? value.split(",") : value,
                      });
                    }}
                  >
                    <MenuItem value={"ALL"}>All Actions</MenuItem>
                    <MenuItem value={"BRANDS"}>Add/Edit Brands</MenuItem>
                    <MenuItem value={"USERS"}>Add/Edit Users</MenuItem>
                    <MenuItem value={"SOURCES"}>Add/Edit Sources</MenuItem>
                    <MenuItem value={"DRIVERS"}>Setup Drivers</MenuItem>
                    <MenuItem value={"GOOGLE_SEARCH"}>
                      Setup Google Search
                    </MenuItem>
                    <MenuItem value={"ALERTS"}>Setup Alerts</MenuItem>
                  </Select>
                </>
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  createUserModel: false,
                  selectedBrands: [],
                  selectedMainBrand: false,
                  selectedDashboards: [],
                  selectedLanguages: [],
                  newUser: null,
                  newUsername: null,
                  newEmail: null,
                  newPhone: null,
                  hasSubDrivers: false,
                  hasAIDrivers: false,
                  canEditSentiment: false,
                  canExcludePagePosts: false,
                  exportRawData: false,
                  canExportVisToExcel: false,
                  hasUncategorizedDrivers: false,
                  demoAccount: false,

                  validityPeriod: null,

                  isAdmin: false,
                  accessUsers: [],
                  accessDashboards: [],
                  actions: [],

                  password: "",
                  confirmPassword: "",
                  showPassword: false,
                  error: null,
                  info: null,
                });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" form="create-user-form" label="Submit">
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.editUserModal}
          onBackdropClick="false"
          disableBackdropClick={true}
          onClose={() => {
            this.setState({
              editUserModal: false,
            });
          }}
        >
          <DialogTitle>
            Edit Existing User:{" "}
            {this.state.selectedUser ? this.state.selectedUser.user : ""}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here, you can update user profile.
            </DialogContentText>

            <br />
            <br />

            <form id="edit-user-form" onSubmit={this.handleSubmitEditUser}>
              <TextField
                required
                id="username"
                label="Username"
                type=""
                fullWidth
                variant="standard"
                disabled={true}
                value={this.state.newUsername}
                // inputProps={
                //   { readOnly: true, }
                // }
              />

              <br />
              <br />

              {/* <TextField
                required
                id="email"
                label="Email Address"
                type=""
                fullWidth
                variant="standard"
                value={this.state.newEmail}
                onChange={(e) => {
                  this.setState({ newEmail: e.target.value });
                }}
              />

              <br />
              <br /> */}

              {/* <TextField

              required
              id="phone"
              label="Mobile number"
              type="phone"
              fullWidth
              variant="standard"
              value={this.state.newPhone}
              onChange={(e) => {
                this.setState({ newPhone: e.target.value });
              }}
            />

            <br />
            <br /> */}

              <Autocomplete
                multiple
                disableCloseOnSelect={true}
                id="tags-standard"
                options={
                  this.state.brands
                    ? this.state.brands.map((item, index) => {
                        return item.name;
                      })
                    : []
                }
                filterSelectedOptions
                onChange={(e, value) => {
                  this.setState({
                    selectedBrands: value,
                    editChanges: {
                      ...this.state.editChanges,
                      brands: value,
                    },
                  });
                }}
                value={this.state.selectedBrands}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Brands"
                    placeholder="Brand"
                    required
                    inputProps={{
                      ...params.inputProps,
                      required: this.state.selectedBrands.length === 0,
                    }}
                  />
                )}
              />

              <br />
              <br />

              <InputLabel id="demo-simple-select-autowidth-label2">
                Main Brand *
              </InputLabel>
              <Select
                labelId="mainbrand"
                id="mainbrand"
                value={this.state.selectedMainBrand}
                label="Main Brand"
                placeholder="Main Brand"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  this.setState({
                    selectedMainBrand: e.target.value,
                    editChanges: {
                      ...this.state.editChanges,
                      main_brand: e.target.value,
                    },
                  });
                }}
              >
                {this.state.selectedBrands
                  ? this.state.selectedBrands.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })
                  : []}
              </Select>

              <br />
              <br />

              <Autocomplete
                multiple
                id="tags-standard"
                options={
                  this.state.allDashboards ? this.state.allDashboards : []
                }
                filterSelectedOptions
                disableCloseOnSelect={true}
                onChange={(e, value) => {
                  this.setState({
                    selectedDashboards: value,
                    editChanges: {
                      ...this.state.editChanges,
                      dashboards: value,
                    },
                  });
                }}
                value={this.state.selectedDashboards}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Dashboards"
                    placeholder="Dashboard"
                    inputProps={{
                      ...params.inputProps,
                      required: this.state.selectedDashboards.length === 0,
                    }}
                  />
                )}
              />

              <br />
              <br />

              <InputLabel id="demo-simple-select-autowidth-label2">
                Supported Languages *
              </InputLabel>
              <Select
                labelId="languages"
                id="languages"
                value={this.state.selectedLanguages}
                label="Languages"
                placeholder="languages"
                fullWidth
                variant="standard"
                multiple
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  this.setState({
                    selectedLanguages:
                      typeof value === "string" ? value.split(",") : value,
                    editChanges: {
                      ...this.state.editChanges,
                      supported_languages:
                        typeof value === "string" ? value.split(",") : value,
                    },
                  });
                }}
              >
                {this.state.supported_languages &&
                  this.state.supported_languages.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="hasSubDrivers"
                    checked={this.state.hasSubDrivers}
                    onChange={(e) => {
                      this.setState({
                        hasSubDrivers: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          hasSubDrivers: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="SubDrivers"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="hasAIDrivers"
                    checked={this.state.hasAIDrivers}
                    onChange={(e) => {
                      this.setState({
                        hasAIDrivers: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          hasAIDrivers: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="hasAIDrivers"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="canEditSentiment"
                    checked={this.state.canEditSentiment}
                    onChange={(e) => {
                      this.setState({
                        canEditSentiment: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          canEditSentiment: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="canEditSentiment"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="canExcludePagePosts"
                    checked={this.state.canExcludePagePosts}
                    onChange={(e) => {
                      this.setState({
                        canExcludePagePosts: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          canExcludePagePosts: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="canExcludePagePosts"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="exportRawData"
                    checked={this.state.exportRawData}
                    onChange={(e) => {
                      this.setState({
                        exportRawData: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          exportRawData: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="exportRawData"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="canExportVisToExcel"
                    checked={this.state.canExportVisToExcel}
                    onChange={(e) => {
                      this.setState({
                        canExportVisToExcel: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          canExportVisToExcel: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="canExportVisToExcel"
              />

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="hasUncategorizedDrivers"
                    checked={this.state.hasUncategorizedDrivers}
                    onChange={(e) => {
                      this.setState({
                        hasUncategorizedDrivers: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          hasUncategorizedDrivers: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="hasUncategorizedDrivers"
              />

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="demoAccount"
                    checked={this.state.demoAccount}
                    onChange={(e) => {
                      this.setState({
                        demoAccount: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          demoAccount: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="demoAccount"
              />

              <br />
              <br />

              <InputLabel id="validity-title">Account Expiry *</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="To"
                  inputFormat="MM/dd/yyyy"
                  required
                  disablePast
                  value={this.state.validityPeriod}
                  onChange={(value) => {
                    this.setState({
                      validityPeriod: value,
                      editChanges: {
                        ...this.state.editChanges,
                        validityPeriod: value,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onChange={(e) => {
                        const today = new Date(Date.now());
                        var inputDate = new Date(e.target.value);
                        if (inputDate < today) {
                          this.setState({
                            error:
                              "Please input a valid date! Which is not earlier than today.",
                          });
                        } else {
                          this.setState({ error: null });
                        }
                      }}
                      required
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <br />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    name="isAdmin"
                    checked={this.state.isAdmin}
                    onChange={(e) => {
                      this.setState({
                        isAdmin: e.target.checked,
                        editChanges: {
                          ...this.state.editChanges,
                          isAdmin: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label="isAdmin"
              />

              <br />
              <br />

              {this.state.isAdmin && (
                <>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="AccessUsers"
                    options={this.state.users ? this.state.users : []}
                    filterSelectedOptions
                    onChange={(e, value) => {
                      this.setState({
                        accessUsers: value,
                        editChanges: {
                          ...this.state.editChanges,
                          accessUsers: value,
                        },
                      });
                    }}
                    value={this.state.accessUsers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Access Users"
                        placeholder="Access"
                      />
                    )}
                  />

                  <br />
                  <br />

                  <InputLabel id="AccessDashboards">
                    Access Dashboards
                  </InputLabel>
                  <Select
                    labelId="AccessDashboards"
                    id="AccessDashboards"
                    value={this.state.accessDashboards}
                    label="Access Dashboards"
                    placeholder="Access Dashboards"
                    multiple
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      this.setState({
                        accessDashboards:
                          typeof value === "string" ? value.split(",") : value,
                        editChanges: {
                          ...this.state.editChanges,
                          accessDashboards:
                            typeof value === "string"
                              ? value.split(",")
                              : value,
                        },
                      });
                    }}
                  >
                    {this.state.selectedDashboards &&
                      this.state.selectedDashboards
                        .filter(
                          (el) => !this.state.extraDashboards.includes(el)
                        )
                        .map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                  </Select>

                  <br />
                  <br />

                  <InputLabel id="Actions">Actions</InputLabel>
                  <Select
                    labelId="Actions"
                    id="Actions"
                    value={this.state.actions}
                    label="Actions"
                    placeholder="Actions"
                    multiple
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      this.setState({
                        actions:
                          typeof value === "string" ? value.split(",") : value,
                        editChanges: {
                          ...this.state.editChanges,
                          actions:
                            typeof value === "string"
                              ? value.split(",")
                              : value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"ALL"}>All Actions</MenuItem>
                    <MenuItem value={"BRANDS"}>Add/Edit Brands</MenuItem>
                    <MenuItem value={"USERS"}>Add/Edit Users</MenuItem>
                    <MenuItem value={"SOURCES"}>Add/Edit Sources</MenuItem>
                    <MenuItem value={"DRIVERS"}>Setup Drivers</MenuItem>
                    <MenuItem value={"GOOGLE_SEARCH"}>
                      Setup Google Search
                    </MenuItem>
                    <MenuItem value={"ALERTS"}>Setup Alerts</MenuItem>
                  </Select>
                </>
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  editUserModal: false,

                  selectedBrands: [],
                  selectedMainBrand: "",
                  selectedDashboards: [],
                  selectedLanguages: [],
                  newUser: null,
                  newUsername: null,
                  newEmail: null,
                  newPhone: null,
                  hasSubDrivers: false,
                  hasAIDrivers: false,
                  canEditSentiment: false,
                  canExcludePagePosts: false,
                  exportRawData: false,
                  canExportVisToExcel: false,
                  hasUncategorizedDrivers: false,
                  demoAccount: false,

                  validityPeriod: null,

                  isAdmin: false,
                  accessUsers: [],
                  accessDashboards: [],
                  actions: [],
                  editChanges: {},
                });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" form="edit-user-form" label="Submit">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.resetPasswordModal}
          onClose={() => {
            this.setState({ resetPasswordModal: false });
          }}
          onBackdropClick="false"
          disableBackdropClick={true}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Resetting Password For User:{" "}
            {this.state.selectedUser ? this.state.selectedUser.user : ""}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please confirm you want to send new auto-generate password to the
              user in order to login to the tool.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() =>
                this.setState({ selectedUser: null, resetPasswordModal: false })
              }
            >
              Cancel
            </Button>
            <Button onClick={this.handleResetPassword}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UserTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired,
  listBrands: PropTypes.func.isRequired,
  listDashboards: PropTypes.func.isRequired,
  listLanguages: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  listUsers,
  listEntities,
  listDashboards,
  listLanguages,
  getUserDetails,
  createRequest,
  createUser,
  editUser,
  resetPassword,
})(UserTab);
