import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SvgIcon from "@material-ui/core/SvgIcon";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

import { logoutUser } from "../../actions/authActions";
import { listSources, getSourceDetails } from "../../actions/sourceActions";
import { createRequest } from "../../actions/requestActions";

class SourceTab extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      sources: [],
      selectedSources: [],
      sources_details: [],
      pullDataModalOpen: false,
      selectedSource: null,
      startDate: null,
      endDate: null,
      activateSourceModalOpen: false,
      editSourceModalOpen: false,
      selectedInterval: null,
    };
    this.init = this.init.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceSearch = this.onSourceSearch.bind(this);
    this.onDisplayClick = this.onDisplayClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      this.setState({
        sources: nextProps.auth.sources,
        sources_details: nextProps.auth.sources_details
          ? nextProps.auth.sources_details
          : [],
      });
    }
  }

  init() {
    this.props.listSources();
  }

  onSourceChange(e, value) {
    this.setState({
      selectedSources: value,
    });
  }

  onSourceSearch(e) {
    this.props.listSources(e.target.value);
  }

  onDisplayClick(e) {
    e.preventDefault();
    this.props.getSourceDetails({
      sources: this.state.selectedSources.map((el) => el.name),
    });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { user } = this.props.auth;

    const getChildren = (obj) => {
      return Object.keys(obj).map((k) => {
        const hasChildren = typeof obj[k] === "object";
        return {
          id: Math.random() * 10000,
          name: `${obj[k].name || k}${hasChildren ? "" : `: ${obj[k]}`}`,
          children: hasChildren ? getChildren(obj[k]) : [],
        };
      });
    };

    const getData = (objs) => {
      return objs.map((obj) => ({
        id: Math.random() * 10000,
        name: obj.name,
        children: getChildren(obj),
        source: obj,
      }));
    };

    const renderTree = (nodes) => {
      return (
        <TreeItem key={nodes.id} nodeId={nodes.id + ""} label={nodes.name}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
          {nodes.source && (
            <>
              {/* Showing button to request data pull */}
              <Button
                variant="text"
                onClick={() => {
                  this.setState({
                    pullDataModalOpen: true,
                    selectedSource: nodes.source,
                  });
                }}
              >
                Request data pull
              </Button>

              {/* Source is not active, showing button to activate */}
              {!nodes.source.active && (
                <Button
                  variant="text"
                  onClick={() => {
                    this.setState({
                      activateSourceModalOpen: true,
                      selectedSource: nodes.source,
                    });
                  }}
                >
                  Activate Source
                </Button>
              )}

              {/* Other edits on source */}
              <Button
                variant="text"
                onClick={() => {
                  this.setState({
                    editSourceModalOpen: true,
                    selectedSource: nodes.source,
                    selectedInterval: nodes.source.interval_group,
                  });
                }}
              >
                Edit Source
              </Button>
            </>
          )}
        </TreeItem>
      );
    };

    function MinusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
      );
    }

    function PlusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
      );
    }

    function CloseSquare(props) {
      return (
        <SvgIcon
          className="close"
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
      );
    }
    const filterOptions = createFilterOptions({
      // matchFrom: 'start',
      stringify: (option) =>
        `${option.name};${option.user ? option.user : ""};${
          option.link ? option.link : ""
        };${option.keywords ? option.keywords.join(",") : ""}`,
    });
    return (
      <div>
        <div className="row">
          <div className="landing-copy col s12 center-align">
            <h4>
              <b>Welcome</b> {user.name.split(" ")[0]}
            </h4>
            <div className="row">
              <div className="col s9">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={this.state.sources}
                  getOptionLabel={(option) => option.name}
                  filterOptions={filterOptions}
                  onChange={this.onSourceChange}
                  onInput={this.onSourceSearch}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Selected Sources"
                      placeholder="Source"
                    />
                  )}
                />
              </div>
              <div className="col s3">
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                  }}
                  onClick={this.onDisplayClick}
                  className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                >
                  Display
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col s8">
                <TreeView
                  style={{ textAlign: "left" }}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  // defaultEndIcon={<CloseSquare />}
                  multiSelect
                >
                  {getData(this.state.sources_details).map(renderTree)}
                </TreeView>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.pullDataModalOpen}
          onClose={() => {
            this.setState({
              pullDataModalOpen: false,
              startDate: null,
              endDate: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to pull data
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From"
                inputFormat="MM/dd/yyyy"
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                label="To"
                inputFormat="MM/dd/yyyy"
                value={this.state.endDate}
                onChange={(value) => {
                  this.setState({ endDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {this.state.selectedSource &&
            this.state.selectedSource.gaps &&
            this.state.selectedSource.gaps.length ? (
              <Button
                variant="text"
                onClick={() => {
                  const gaps = this.state.selectedSource.gaps.map((g) => {
                    const gap = new Date();
                    gap.setDate(1);
                    gap.setYear(g.split("-")[0]);
                    gap.setMonth(g.split("-")[1] - 1);
                    return gap;
                  });

                  const startDate = gaps[0];
                  const endDate = gaps[gaps.length - 1];
                  endDate.setMonth(endDate.getMonth() + 1);
                  endDate.setDate(endDate.getDate() - 1);
                  gaps.sort((a, b) => a < b);
                  this.setState({
                    startDate,
                    endDate,
                  });
                }}
              >
                Fill Gaps
              </Button>
            ) : null}

            <Button
              variant="outlined"
              disabled={
                this.state.startDate > this.state.endDate ? true : false
              }
              onClick={() => {
                const today = new Date();
                const lastTwoWeeks = new Date();
                lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 15);

                this.props.createRequest({
                  action: "PULL_DATA",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                    startDate: this.state.startDate || lastTwoWeeks,
                    endDate: this.state.endDate || today,
                  },
                });
                this.setState({
                  pullDataModalOpen: false,
                  startDate: null,
                  endDate: null,
                });
              }}
            >
              Send Request
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.activateSourceModalOpen}
          onClose={() => {
            this.setState({
              activateSourceModalOpen: false,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to activate:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                this.props.createRequest({
                  action: "ACTIVATE_SOURCE",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                  },
                });
                this.setState({
                  activateSourceModalOpen: false,
                });
              }}
            >
              Request to activate
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.editSourceModalOpen}
          onClose={() => {
            this.setState({
              editSourceModalOpen: false,
              selectedInterval: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedInterval}
              label="Interval"
              onChange={(e) => {
                console.log(e.target.value);
                this.setState({ selectedInterval: e.target.value });
              }}
            >
              <MenuItem value={"1-min"}>1 minute</MenuItem>
              <MenuItem value={"5-min"}>5 minutes</MenuItem>
              <MenuItem value={"15-min"}>15 minutes</MenuItem>
              <MenuItem value={"1-hour"}>1 hour</MenuItem>
              <MenuItem value={"6-hour"}>6 hours</MenuItem>
              <MenuItem value={"1-day"}>1 day</MenuItem>
            </Select>

            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              disabled
            />

            <Button
              variant="outlined"
              onClick={() => {
                if (this.state.selectedInterval) {
                  this.props.createRequest({
                    action: "EDIT_SOURCE",
                    email: `${user.name}`,
                    name: this.state.selectedSource.name,
                    details: {
                      source: this.state.selectedSource,
                      interval_group: this.state.selectedInterval,
                    },
                  });
                  this.setState({
                    editSourceModalOpen: false,
                    selectedInterval: null,
                  });
                }
              }}
            >
              Edit
            </Button>
          </Box>
        </Modal>
      </div>
    );
  }
}

SourceTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  listSources: PropTypes.func.isRequired,
  getSourceDetails: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,

  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  listSources,
  getSourceDetails,
  createRequest,
})(SourceTab);
