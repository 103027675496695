import {
  CREATE_REQUEST,
  LIST_REQUESTS,
  HANDLE_REQUEST,
  HANDLE_REQUEST_SUCCESS,
  HANDLE_REQUEST_FAILED,
} from '../actions/types';

const initialState = {
  requestsList: [],
  requestHandlingInProgress: true,
  requestHandled: true,
  message: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_REQUEST:
      return state;
    case LIST_REQUESTS:
      return { ...state, requestsList: action.payload };
    case HANDLE_REQUEST:
      return {
        ...state,
        requestHandlingInProgress: true,
        requestHandled: false,
      };
    case HANDLE_REQUEST_SUCCESS:
      return {
        ...state,
        requestHandlingInProgress: false,
        requestHandled: true,
      };
    case HANDLE_REQUEST_FAILED:
      return {
        ...state,
        requestHandlingInProgress: false,
        requestHandled: false,
        message: action.payload,
      };
    default:
      return state;
  }
}
