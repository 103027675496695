import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SvgIcon from "@material-ui/core/SvgIcon";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { logoutUser } from "../../actions/authActions";
import {
  listBrands,
  getBrandDetails,
  createBrand,
  UploadEntityImage,
} from "../../actions/sourceActions";
import { createRequest } from "../../actions/requestActions";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";

import { listSources } from "../../actions/sourceActions";
import axios from "axios";

class BrandTab extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      brands: [],
      selectedBrands: [],
      brands_details: [],
      pullDataModalOpen: false,
      selectedSource: null,
      startDate: null,
      endDate: null,
      activateSourceModalOpen: false,
      editSourceModalOpen: false,
      selectedInterval: null,
      createBrandModal: false,
      newBrandName: null,
      selectedColor: "#9e1665",
      selectedImage: null,
      sources: [],
      selectedSources: [],
      colors: [
        "#9e1665",
        "#ff0095",
        "#3a0ca3",
        "#3a0ca3",
        "#ff7701",
        "#a82f01",
        "#ff80b7",
        "#4361ee",
        "#d6007d",
        "#ffa9cf",
        "#e14800",
        "#fdbb7f",
        "#d6dade",
        "#ef233c",
        "#6bb95c",
        "#c22525",
        "#4c924e",
        "#ff9c00",
        "#3f37c9",
        "#c4e3be",
        "#4895ef",
        "#4cc9f0",
        "#fe9079",
        "#e8eaeb",
        "#f7f7f7",
        "#c5c5c5",
        "#f9a7b1",
        "#1e1f26",
        "#e91d76",
        "Custom color",
      ],
      customColor: null,
      customColorError: false,
      errorInLogo: false,
      errorLogoMessage: null,
      brandNameError: false,
      error: null,
      sucess: null,
    };
    this.init = this.init.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceSearch = this.onSourceSearch.bind(this);
    this.onDisplayClick = this.onDisplayClick.bind(this);
    this.handleSubmitCreateBrand = this.handleSubmitCreateBrand.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      this.setState({
        brands: nextProps.auth.brands ? nextProps.auth.brands : [],
        sources: nextProps.auth.sources ? nextProps.auth.sources : [],
        brands_details: nextProps.auth.brands_details
          ? nextProps.auth.brands_details
          : [],
        error: nextProps.auth.brandError ? nextProps.auth.brandError : false,
        sucess: nextProps.auth.brandCreated
          ? nextProps.auth.brandCreated
          : false,
      });
    }
  }

  init() {
    this.props.listSources();
    this.props.listBrands();
  }

  onBrandChange(e, value) {
    this.setState({
      selectedBrands: value,
    });
  }

  onDisplayClick(e) {
    e.preventDefault();
    this.props.getBrandDetails({ brands: this.state.selectedBrands });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  clearForm = () => {
    this.setState({
      createBrandModal: false,
      newBrandName: null,
      selectedColor: null,
      selectedSources: [],
      selectedImage: null,
      errorInLogo: false,
      errorLogoMessage: null,
      brandNameError: false,
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg+xml";
    if (!isJpgOrPng) {
      this.setState({
        errorInLogo: true,
        errorLogoMessage: `${file.type} is not a image`,
      });
      return true;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({
        errorInLogo: true,
        errorLogoMessage: "Image must be smaller than 2MB!",
      });
      return true;
    }
    return false;
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && !this.beforeUpload(file)) {
      this.setState({
        errorInLogo: false,
        errorLogoMessage: null,
        selectedImage: file,
      });
    } else {
      this.setState({
        selectedImage: null,
      });
    }
  };

  onSourceChange(e, value) {
    this.setState({
      selectedSources: value,
    });
  }

  onSourceSearch(e) {
    this.props.listSources(e.target.value);
  }

  handleSubmitCreateBrand = (e) => {
    e.preventDefault();

    if (this.state.brandNameError) {
      return;
    }

    if (this.state.selectedImage) {
      const formData = new FormData();
      formData.append("file", this.state.selectedImage);
      axios
        .post(
          `/api/brand-tab/UploadEntityImage?createdBy=${this.props.auth.user.email}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.data, "sssssss");
          this.props.createBrand({
            brand: {
              name: this.state.newBrandName,
              image: response.data ? response.data : undefined,
              color:
                this.state.selectedColor === "Custom color"
                  ? this.state.customColor
                  : this.state.selectedColor
                  ? this.state.selectedColor
                  : undefined,
              sources:
                this.state.selectedSources.length > 0
                  ? this.state.selectedSources
                  : undefined,
            },

            createdBy: this.props.auth.user.email,
          });
          this.clearForm();
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ error: true });
          this.clearForm();
        });
    } else {
      this.props.createBrand({
        brand: {
          name: this.state.newBrandName,
          color:
            this.state.selectedColor === "Custom color"
              ? this.state.customColor
              : this.state.selectedColor
              ? this.state.selectedColor
              : undefined,
          sources:
            this.state.selectedSources.length > 0
              ? this.state.selectedSources
              : undefined,
        },

        createdBy: this.props.auth.user.email,
      });
      this.setState({
        createBrandModal: false,
        newBrandName: null,
        selectedColor: null,
        selectedSources: [],
      });
    }
  };

  render() {
    const { user } = this.props.auth;

    const getChildren = (obj, options = {}) => {
      return Object.keys(obj).map((k) => {
        const hasChildren = typeof obj[k] === "object";
        const sources =
          Array.isArray(obj[k]) && k === "sources" ? obj[k] : null;

        return {
          id: Math.random() * 10000,
          name: `${obj[k].name || k}${hasChildren ? "" : `: ${obj[k]}`}`,
          children: hasChildren ? getChildren(obj[k], { sources }) : [],
          sources: options.sources,
        };
      });
    };

    const getData = (objs) => {
      return objs.map((obj) => ({
        id: Math.random() * 10000,
        name: obj.brand,
        children: getChildren(obj),
      }));
    };

    const renderTree = (nodes, index) => {
      return (
        <TreeItem key={nodes.id} nodeId={nodes.id + ""} label={nodes.name}>
          {Array.isArray(nodes.children)
            ? nodes.children.map((node, i) => renderTree(node, i))
            : null}
          {nodes.sources && nodes.sources.length && (
            <>
              {/* Showing button to request data pull */}
              <Button
                variant="text"
                onClick={() => {
                  this.setState({
                    pullDataModalOpen: true,
                    selectedSource: nodes.sources[index],
                  });
                }}
              >
                Request data pull
              </Button>

              {/* Source is not active, showing button to activate */}
              {!nodes.sources[index].active && (
                <Button
                  variant="text"
                  onClick={() => {
                    this.setState({
                      activateSourceModalOpen: true,
                      selectedSource: nodes.sources[index],
                    });
                  }}
                >
                  Activate Source
                </Button>
              )}

              {/* Other edits on source */}
              <Button
                variant="text"
                onClick={() => {
                  console.log(nodes.sources[index].interval_group);
                  this.setState({
                    editSourceModalOpen: true,
                    selectedSource: nodes.sources[index],
                    selectedInterval: nodes.sources[index].interval_group,
                  });
                }}
              >
                Edit Source
              </Button>
            </>
          )}
        </TreeItem>
      );
    };

    function MinusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
      );
    }

    function PlusSquare(props) {
      return (
        <SvgIcon
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
      );
    }

    function CloseSquare(props) {
      return (
        <SvgIcon
          className="close"
          fontSize="inherit"
          style={{ width: 14, height: 14 }}
          {...props}
        >
          {/* tslint:disable-next-line: max-line-length */}
          <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
      );
    }

    const filterOptions = createFilterOptions({
      // matchFrom: 'start',
      stringify: (option) =>
        `${option.name};${option.user ? option.user : ""};${
          option.link ? option.link : ""
        };${option.keywords ? option.keywords.join(",") : ""}`,
    });

    const handleClear = () => {
      this.setState({
        selectedImage: null,
      });
    };

    return (
      <div>
        <div className="row">
          <div className="landing-copy col s12 center-align">
            <h4>
              <b>Welcome</b> {user.name.split(" ")[0]}
            </h4>
            <div className="row">
              <div className="col s8">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={this.state.brands}
                  filterSelectedOptions
                  onChange={this.onBrandChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Selected Brands"
                      placeholder="Brand"
                    />
                  )}
                />
              </div>
              <div className="col s2">
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                  }}
                  onClick={this.onDisplayClick}
                  className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                >
                  Display
                </button>
              </div>
              <div className="col s2">
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    this.setState({
                      createBrandModal: true,
                    });
                  }}
                  className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                >
                  Create New Brand
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col s8">
                <TreeView
                  style={{ textAlign: "left" }}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  // defaultEndIcon={<CloseSquare />}
                  multiSelect
                >
                  {getData(this.state.brands_details).map(renderTree)}
                </TreeView>
              </div>
            </div>
          </div>
        </div>

        {this.state.error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.error ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ error: false })}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {"Somthing went wrong! Brand was not created"}
            </Alert>
          </Snackbar>
        )}

        {this.state.sucess && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.sucess ? true : false}
            autoHideDuration={5000}
            onClose={() => this.setState({ sucess: false })}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {"Brand created successfully"}
            </Alert>
          </Snackbar>
        )}

        <Dialog
          open={this.state.createBrandModal}
          onBackdropClick="false"
          disableBackdropClick={true}
          onClose={() => {
            this.clearForm();
          }}
        >
          <DialogTitle>Create New Brand</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here, you can define brand name, image, sources and color for a
              new brand.
            </DialogContentText>

            <br />
            <br />

            <form id="create-user-form" onSubmit={this.handleSubmitCreateBrand}>
              <TextField
                required
                id="name"
                label="Name"
                type=""
                fullWidth
                error={this.state.brandNameError}
                variant="standard"
                value={this.state.newBrandName}
                onChange={(e) => {
                  var regex = /^[^\s]+(\s+[^\s]+)*$/;
                  const emptyOrHasSpace =
                    !regex.test(e.target.value) ||
                    !e.target.value.replace(/\s/g, "").length;
                  if (emptyOrHasSpace) {
                    this.setState({
                      newBrandName: e.target.value,
                      brandNameError: true,
                    });
                  } else {
                    this.setState({
                      newBrandName: e.target.value,
                      brandNameError: false,
                    });
                  }
                }}
              />

              {this.state.brandNameError ? (
                <p style={{ color: "red" }}>
                  Name can not contain space at start or the end
                </p>
              ) : (
                <>
                  <br />
                  <br />
                </>
              )}

              <Autocomplete
                multiple
                id="tags-standard"
                options={this.state.sources}
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions}
                onChange={this.onSourceChange}
                onInput={this.onSourceSearch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Selected Sources"
                    placeholder="Source"
                    style={{ marginBottom: "20px" }}
                  />
                )}
              />

              <InputLabel id="demo-simple-select-autowidth-label2">
                Color
              </InputLabel>
              <Select
                labelId="color"
                id="color"
                label="Color"
                placeholder="Color"
                fullWidth
                required
                variant="standard"
                defaultValue={this.state.colors[0]}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  this.setState({
                    selectedColor: value,
                  });
                }}
              >
                {this.state.colors &&
                  this.state.colors.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        <span
                          style={{
                            height: "20px",
                            width: "20px",
                            backgroundColor: item,
                            marginRight: "5px",
                          }}
                        ></span>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>

              <br />
              <br />
              {this.state.selectedColor === "Custom color" && (
                <div>
                  {" "}
                  <InputLabel id="demo-simple-select-autowidth-label2">
                    Custom Color
                  </InputLabel>
                  <TextField
                    required
                    id="customColor"
                    label={
                      this.state.customColorError
                        ? "Please enter a valid color"
                        : "#444444"
                    }
                    type=""
                    fullWidth
                    variant="standard"
                    error={this.state.customColorError}
                    // value={this.state.newBrandName}
                    onChange={(e) => {
                      const regexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
                      if (regexPattern.test(e.target.value)) {
                        this.setState({
                          customColor: e.target.value,
                          customColorError: false,
                        });
                      } else {
                        this.setState({
                          customColorError: true,
                        });
                      }
                    }}
                  />
                </div>
              )}

              <br />

              <div>
                <input
                  type="file"
                  accept="image/*"
                  id="image-upload-input"
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                />
                <label htmlFor="image-upload-input">
                  <Button variant="contained" component="span">
                    Upload Image
                  </Button>

                  <br />
                  <br />

                  {this.state.errorInLogo && (
                    <p style={{ color: "red" }}>
                      {this.state.errorLogoMessage}
                    </p>
                  )}

                  {this.state.selectedImage && (
                    <TextField
                      id="name"
                      type=""
                      fullWidth
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClear}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.selectedImage.name}
                    />
                  )}
                </label>

                <br />
                <br />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.clearForm();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" form="create-user-form" label="Submit">
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          open={this.state.pullDataModalOpen}
          onClose={() => {
            this.setState({
              pullDataModalOpen: false,
              startDate: null,
              endDate: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to pull data
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From"
                inputFormat="MM/dd/yyyy"
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                label="To"
                inputFormat="MM/dd/yyyy"
                value={this.state.endDate}
                onChange={(value) => {
                  this.setState({ endDate: value });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {this.state.selectedSource &&
              this.state.selectedSource.gaps &&
              this.state.selectedSource.gaps.length && (
                <Button
                  variant="text"
                  onClick={() => {
                    const gaps = this.state.selectedSource.gaps.map((g) => {
                      const gap = new Date();
                      gap.setDate(1);
                      gap.setYear(g.split("-")[0]);
                      gap.setMonth(g.split("-")[1] - 1);
                      return gap;
                    });

                    gaps.sort((a, b) => a < b);
                    const startDate = gaps[0];
                    const endDate = gaps[gaps.length - 1];
                    endDate.setMonth(endDate.getMonth() + 1);
                    endDate.setDate(endDate.getDate() - 1);
                    this.setState({
                      startDate,
                      endDate,
                    });
                  }}
                >
                  Fill Gaps
                </Button>
              )}

            <Button
              variant="outlined"
              disabled={
                this.state.startDate > this.state.endDate ? true : false
              }
              onClick={() => {
                const today = new Date();
                const lastTwoWeeks = new Date();
                lastTwoWeeks.setDate(lastTwoWeeks.getDate() - 15);

                this.props.createRequest({
                  action: "PULL_DATA",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                    startDate: this.state.startDate || lastTwoWeeks,
                    endDate: this.state.endDate || today,
                  },
                });
                this.setState({
                  pullDataModalOpen: false,
                  startDate: null,
                  endDate: null,
                });
              }}
            >
              Send Request
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.activateSourceModalOpen}
          onClose={() => {
            this.setState({
              activateSourceModalOpen: false,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request to activate:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                this.props.createRequest({
                  action: "ACTIVATE_SOURCE",
                  email: `${user.name}`,
                  name: this.state.selectedSource.name,
                  details: {
                    source: this.state.selectedSource,
                  },
                });
                this.setState({
                  activateSourceModalOpen: false,
                });
              }}
            >
              Request to activate
            </Button>
          </Box>
        </Modal>

        <Modal
          open={this.state.editSourceModalOpen}
          onClose={() => {
            this.setState({
              editSourceModalOpen: false,
              selectedInterval: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit:{" "}
              {this.state.selectedSource ? this.state.selectedSource.name : ""}
            </Typography>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedInterval}
              label="Interval"
              onChange={(e) => {
                this.setState({ selectedInterval: e.target.value });
              }}
            >
              <MenuItem value={"1-min"}>1 minute</MenuItem>
              <MenuItem value={"5-min"}>5 minutes</MenuItem>
              <MenuItem value={"15-min"}>15 minutes</MenuItem>
              <MenuItem value={"1-hour"}>1 hour</MenuItem>
              <MenuItem value={"6-hour"}>6 hours</MenuItem>
              <MenuItem value={"1-day"}>1 day</MenuItem>
            </Select>

            <Button
              variant="outlined"
              onClick={() => {
                if (this.state.selectedInterval) {
                  this.props.createRequest({
                    action: "EDIT_SOURCE",
                    email: `${user.name}`,
                    name: this.state.selectedSource.name,
                    details: {
                      source: this.state.selectedSource,
                      interval_group: this.state.selectedInterval,
                    },
                  });
                  this.setState({
                    editSourceModalOpen: false,
                    selectedInterval: null,
                  });
                }
              }}
            >
              Edit
            </Button>
          </Box>
        </Modal>
      </div>
    );
  }
}

BrandTab.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  listBrands: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  listSources: PropTypes.func.isRequired,
  createBrand: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  listBrands,
  getBrandDetails,
  createRequest,
  listSources,
  createBrand,
})(BrandTab);
