import axios from 'axios';

import {
  LIST_REQUESTS,
  CREATE_REQUEST,
  HANDLE_REQUEST,
  GET_ERRORS,
  HANDLE_REQUEST_SUCCESS,
  HANDLE_REQUEST_FAILED,
} from './types';

export const listRequests = (user) => (dispatch) => {
  axios
    .get('/api/requests-tab', {
      params: {
        user
      }
    })
    .then((response) => {
      dispatch({ type: LIST_REQUESTS, payload: response.data.im_requests });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createRequest = (request) => (dispatch) => {
  console.log('request!');
  console.log(request);
  axios
    .post('/api/requests-tab', { request })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: CREATE_REQUEST,
        payload: response.data.im_request,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const handleRequest = (requestId, user) => (dispatch) => {
  dispatch({ type: HANDLE_REQUEST });
  axios
    .get(`/api/requests-tab/${requestId}/approve`, {
      params: {
        user
      }
    })
    .then((response) => {
      console.log('APPROVED!');
      dispatch({ type: HANDLE_REQUEST_SUCCESS });
    })
    .catch((err) => {
      dispatch({
        type: HANDLE_REQUEST_FAILED,
        payload: err.response.data.message,
      });
    });
};
