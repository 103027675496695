export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LIST_USERS = "LIST_USERS";
export const USER_DETAILS = "USER_DETAILS";
export const LIST_BRANDS = "LIST_BRANDS";
export const BRAND_DETAILS = "BRAND_DETAILS";
export const LIST_SOURCES = "LIST_SOURCES";
export const SOURCE_DETAILS = "SOURCE_DETAILS";
export const LIST_REQUESTS = "LIST_REQUESTS";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const HANDLE_REQUEST = "HANDLE_REQUEST";
export const HANDLE_REQUEST_SUCCESS = "HANDLE_REQUEST_SUCCESS";
export const HANDLE_REQUEST_FAILED = "HANDLE_REQUEST_FAILED";
export const LIST_DASHBOARDS = "LIST_DASHBOARDS";
export const LIST_LANGUAGES = "LIST_LANGUAGES";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_BRAND_ERROR = "CREATE_BRAND_ERROR";
export const SENTIMENT_DETAILS = "SENTIMENT_DETAILS";
export const EDIT_SENTIMENT = "EDIT_SENTIMENT";
export const EDIT_SENTIMENT_ERROR = "EDIT_SENTIMENT_ERROR";
export const SEND_SLACK_MESSAGE = "SEND_SLACK_MESSAGE";
export const SLACK_MESSAGE_ERROR = "SLACK_MESSAGE_ERROR";
