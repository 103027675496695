import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import requestsReducer from './requestsReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  requests: requestsReducer,
});
